/* Header */

.header {
    border-bottom: 1px solid #000000;
}
@media screen and (max-width: 942px) {
    .large-screen-header-container {
        display: none !important;
    }
}
@media screen and (min-width: 942px) {
    .small-screen-header-container {
        display: none !important;
    }
}

/* Header (.dark) */

.dark .header {
    border-bottom: 1px solid #ffffff;
}

/* Header Banner */

.header-banner-container {
    background-color: #ceb2ff;
    padding: 10px 2% ;
}
.header-banner {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    font-size: 12px;
}
.header-banner p {
    padding: 0;
    margin: 0;
}
.header-banner p a {
    color: #2962ff;
}

/* Header Banner (.dark) */

.dark .header-banner-container {
    background-color: #9b7fd1;
}

/* Large Screen Header */

.large-screen-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;
    height: 60px;
    background-color: #ffffff;
}
.large-screen-header-column {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 50px;
}
@media screen and (max-width: 1200px)
{
    .large-screen-header-column {
        gap: 20px;
    }
    .large-screen-header-nav {
        gap: 30px !important;
    }
}
.large-screen-header-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-direction: row;
}
.large-screen-header-nav {
    display: flex;
    align-items: center;
    gap: 50px;
    justify-content: center;
    flex-direction: row;
    margin: 0;
    padding: 0;
}
.large-screen-header-nav li {
    list-style-type: none;
    margin: 0;
}
.large-screen-header-nav li a, .large-screen-header-products-btn span {
    padding: 0 3px;
    transition: background 0.3s;
}
.large-screen-header-nav li a:hover, .large-screen-header-products-btn span:hover {
    background: linear-gradient(to left, rgba(158, 103, 255, 0.4) 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: -100%;
}
.large-screen-header-nav li a {
    color: #000000;
    text-decoration: none;
}
.large-screen-header-logo {
    width: auto;
    height: 80px;
    margin: -20px -30px;
}
.large-screen-header-dropdown-wrapper {
    position: relative;
    display: inline-block;
}
.large-screen-header-products-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0;
}
.large-screen-header-dropdown-trigger {
    display: block;
    padding: 20px 0;
}
.large-screen-header-dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 500px;
    z-index: 1;
    border: 1px solid #000000;
    border-top: none;
    padding: 50px;
    flex-direction: column;
    top: 100%;
    left: 0;
    gap: 50px;
}
@media screen and (max-width: 1200px){
    .large-screen-header-dropdown-content {
        min-width: 400px;
        padding: 40px;
        gap: 40px;
    }
}
.large-screen-header-dropdown-content a {
    text-decoration: none;
    color: #000000;
}
.large-screen-header-dropdown-content-row:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}
.large-screen-header-dropdown-content-row:first-child a {
    padding: 0 3px;
    transition: background 0.3s;
}
.large-screen-header-dropdown-content-row:first-child a:hover {
    background: linear-gradient(to left, rgba(158, 103, 255, 0.4) 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: -100%;
}
.large-screen-header-dropdown-wrapper:hover .large-screen-header-dropdown-content {
    display: flex;
}
.large-screen-header-dropdown-content-row:last-child a {
    color: #ffffff;
}

/* Large Screen Header (.dark) */

.dark .large-screen-header-container {
    background-color: #000000;
}
.dark .large-screen-header-nav li a {
    color: #ffffff;
}
.dark .large-screen-header-products-btn {
    color: #ffffff;
}
.dark .large-screen-header-dropdown-content {
    background-color: #000000;
    border-color: #ffffff;
}
.dark .large-screen-header-dropdown-content a {
    color: #ffffff;
}
.dark .large-screen-header-nav li a:hover, .dark .large-screen-header-products-btn span:hover {
    background: linear-gradient(to left, rgba(158, 103, 255, 0.7) 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: -100%;
}
.dark .large-screen-header-dropdown-content-row:first-child a:hover {
    background: linear-gradient(to left, rgba(158, 103, 255, 0.7) 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: -100%;
}
.dark .large-screen-header-dropdown-content-row:last-child a {
    color: #000000;
}
.large-screen-header-dropdown-content-row:last-child a:hover {
    color: #ffffff;
}

/* Small Screen Header */

.small-screen-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;
    height: 60px;
    background-color: #ffffff;
}
.small-screen-header-column {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}
.small-screen-header-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: row;
}
.small-screen-header-logo {
    width: auto;
    height: 80px;
    margin: -20px -30px;
}
.hamburger {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 20px;
    transition: transform 0.3s ease;
    padding: 15px 10px;
    border-radius: 50%;
    z-index: 9998;
}
.bar {
    width: 25px;
    height: 3px;
    background-color: #000000;
    border-radius: 2px;
    transition: transform 0.3s ease;
}
@media screen and (max-width: 448px) {
    .small-screen-header-row:last-child div:last-child {
        display: none;
    }
}
.hamburger-menu, .small-screen-hamburger-menu {
    position: absolute;
    top: -100%;
    left: 0;
    transition: top 0.5s ease;
    z-index: 9997;
    overflow: hidden;
    width: 100%;
    height: 100%;
}
.hamburger-menu.active, .small-screen-hamburger-menu.active {
    top: 0;
}
.hamburger-container {
    display: flex;
    align-items: center;
}
.hamburger-btn {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 20px;
    transition: transform 0.3s ease;
    padding: 15px 14px;
    border-radius: 50%;
    z-index: 9998;
}
.bar {
    width: 25px;
    height: 3px;
    background-color: #000000;
    border-radius: 1px;
    transition: transform 0.3s ease;
}
.hamburger-btn:hover {
    background-color: #000000;
}
.hamburger-btn:hover .bar {
    background-color: #ffffff;
}
.bar:nth-child(1) {
    transform-origin: top left;
}
.bar:nth-child(2) {
    transform-origin: center;
}
.bar:nth-child(3) {
    transform-origin: bottom left;
}
.hamburger-btn.active .bar:nth-child(1) {
    transform: rotate(45deg) translate(3px, -3px);
    background-color: #000000;
}
.hamburger-btn.active .bar:nth-child(2) {
    transform: scaleX(0);
}
.hamburger-btn.active .bar:nth-child(3) {
    transform: rotate(-45deg) translate(3px, 3px);
    background-color: #000000;
}
.hamburger-btn.active:hover {
    background-color: #000000;
}
.hamburger-btn.active:hover .bar:nth-child(3),
.hamburger-btn.active:hover .bar:nth-child(1){
    background-color: #ffffff;
}
.hamburger-menu-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    background-color: #ffffff;
    padding: 0 2%;
    gap: 30px;
    overflow: hidden;
}
.small-screen-hamburger-logo {
    width: auto;
    height: 80px;
    margin: -20px 0;
}
.hamburger-nav {
    padding-top: 2%;
}
.hamburger-nav nav {
    display: flex;
    align-items: center;
    justify-content: center;
}
.hamburger-nav nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.hamburger-nav ul li {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
}
.hamburger-nav ul li a {
    text-decoration: none;
    color: #000000;
    font-weight: 400;
    padding: 0 3px;
    transition: background 0.3s;
}
.hamburger-nav ul li a:hover {
    background: linear-gradient(to left, rgba(158, 103, 255, 0.4) 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: -100%;
}
.hamburger-sub-nav {
    color: #a7a7a7;
    padding-bottom: 2%;
}
.hamburger-sub-nav nav {
    display: flex;
    align-items: center;
    justify-content: center;
}
.hamburger-sub-nav nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.hamburger-sub-nav ul li {
    margin: 0;
    padding: 0;
}
.hamburger-sub-nav ul li a {
    text-decoration: none;
    color: #a7a7a7;
    font-weight: 400;
}
.hamburger-sub-nav ul li a:hover {
    color: #878787;
}
.hamburger-legal-nav-socials {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 15px;
}
.hamburger-legal-nav {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.hamburger-legal-nav p {
    font-family: "Montserrat", sans-serif;
    color: #878787;
    font-size: 11px;
    text-align: center;
}
.hamburger-legal-nav i {
    color: #878787;
    font-size: 20px;
}
@media screen and (max-width: 512px) {
    .hamburger-sub-nav nav ul {
        flex-direction: column;
    }
    .header .bullet {
        display: none;
    }
}

/* Small Screen Header (.dark) */

.dark .small-screen-header-container {
    background-color: #000000;
}
.dark .hamburger-btn:hover .bar {
    background-color: #000000;
}
.dark .hamburger-btn:hover {
    background-color: #ffffff;
}
.dark .bar {
    background-color: #ffffff;
}
.dark .hamburger-menu-container {
    background-color: #000000;
}
.dark .hamburger-nav ul li a {
    color: #ffffff;
}
.hamburger-nav ul li a:hover {
    background: linear-gradient(to left, rgba(158, 103, 255, 0.7) 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: -100%;
}
.dark .hamburger-btn.active:hover .bar:nth-child(3),
.dark .hamburger-btn.active:hover .bar:nth-child(1){
    background-color: #000000;
}
.dark .hamburger-btn.active .bar:nth-child(3), .dark .hamburger-btn.active .bar:nth-child(1) {
    background-color: #ffffff;
}

/* Footer */

.footer-container {
    padding: 7%;
    background-color: #303132;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.footer-wrapper {
    padding: 0 4%;
    flex-wrap: wrap;
}
.footer-wrapper:first-child {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
}
.footer-column h1 {
    color: #ffffff;
    font-size: 0.9em;
    margin: 0;
    padding-bottom: 30px;
    font-weight: 500;
    text-transform: uppercase;
}
.footer-column ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.footer-column ul li {
    margin: 0;
    padding: 0;
}
.footer-column ul li a {
    text-decoration: none;
    color: #f4f4f4;
    font-weight: 500;
    transition: color 0.3s;
    font-size: 0.9em;
}
.footer hr {
    border: none;
    border-top: 1px solid #f4f4f4;
    margin-top: 6%;
    margin-bottom: 3%;
}
.footer-wrapper:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}
.footer-wrapper:last-child .footer-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.footer-wrapper:last-child .footer-row .footer-column, .footer-wrapper:last-child .footer-row .footer-column ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
}
.footer-social-icon {
    cursor: pointer;
}
.footer-social-icon a {
    transition: color 0.3s;
    text-decoration: none;
    color: #ffffff;
    font-size: 20px;
}
.footer-social-icon a:hover {
    color: #878787;
}
.footer a:hover {
    color: #878787;
}
.footer .bullet {
    color: #f4f4f4;
}
.footer-row:last-child .footer-column:first-child ul {
    gap: 10px;
}
.footer-row:last-child .footer-column:first-child ul li a {
    font-size: 0.8em;
}
.footer-column:last-child p {
    color: #f4f4f4;
    font-size: 0.8em;
}
.language-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
    color: #f4f4f4;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 15px;
    border-radius: 50px;
    transition: background-color 0.3s;
}
.language-selector:hover {
    background-color: hsl(0, 0%, 26%);
}
@media screen and (max-width: 700px)
{
    .footer-wrapper:last-child .footer-row:last-child {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }
    .footer-wrapper:last-child ul {
        flex-wrap: wrap;
        justify-content: flex-start !important;
    }
    .footer-wrapper:last-child ul .bullet {
        display: none;
    }
}

/* Footer (.dark) */

.dark .footer-container {
    background-color: #000000;
}
.dark .footer {
    border-top: 1px solid #ffffff;
}

/* Home (index.js) */

/* Home Masthead (home-masthead) */

.home-masthead {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    gap: 40px;
    padding-top: 90px;
    padding-bottom: 90px;
}
.home-masthead h1 {
    font-weight: 500;
    font-size: 4em;
}
.home-masthead-column:first-child {
    flex-basis: 50%;
}
.home-masthead img {
    width: 500px;
    height: auto;
}
.home-masthead-column:first-child .home-masthead-row:last-child
{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}
.coming-soon {
    padding: 0;
    background-color: #67FF9E;
    width: fit-content;
    margin-bottom: -20px;
    font-family: "Sometype Mono", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
}
@media screen and (max-width: 1071px)
{
    .home-masthead img {
        display: none;
    }
    .home-masthead {
        justify-content: unset;
        gap: 0;
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .home-masthead h1 {
        font-size: clamp(48px,7vw,84px);
    }
    .home-masthead-column {
        flex-basis: unset !important;
    }
    .home-masthead .btn-1, .home-masthead .btn-2 {
        width: 100%;
    }
    .home-masthead-column:first-child .home-masthead-row:last-child {
        flex-direction: column;
        gap: 20px;
    }
    .home-masthead-column:first-child .home-masthead-row:last-child div {
        width: 100%;
    }
}

/* Home Masthead (.dark) */

.dark .coming-soon {
    color: #000000;
    background-color: #3ad578;
}

/* Home Banner 1 */

.home-banner-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
    height: 250px;
    background-color: #ceb2ff;
    padding: 0 10%;
}
.home-banner-1 img {
    height: auto;
    width: 500px;
    text-align: right;
}
.home-banner-1 h2 {
    font-size: 2.5em;
    font-weight: 600;
    color: #000000;
    margin: 0;
}
@media screen and (max-width: 1181px)
{
    .home-banner-1 {
        flex-direction: column;
        gap: 20px;
        height: fit-content;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .home-banner-1 img {
        text-align: center;
    }
}
@media screen and (max-width: 768px)
{
    .home-banner-1 {
        padding: 30px 10px;
    }
    .home-banner-1 img {
        width: 300px;
    }
    .home-banner-1 h2 {
        font-size: 2em;
    }
}

/* Home Banner 1 (.dark) */

.dark .home-banner-1 {
    background-color: #9b7fd1;
}
.dark .home-banner-1 img {
    filter: grayscale(100%) brightness(0%);
}

/* Home Instructions */

.home-instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.home-instructions-title {
    padding: 50px 30px;
}
.home-instructions-title h1 {
    font-size: 3em;
    font-weight: 500;
    color: #000000;
    text-align: center;
}
.home-instructions-title p {
    font-size: 1.4em;
    font-weight: 400;
    color: #676767;
    text-align: center;
}
.home-instructions-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.home-instructions-content-column {
    border: 1px solid #000000;
    width: 100%;
    min-height: 500px;
    max-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-instructions-content-column:first-child {
    border-left: none;
}
.home-instructions-content-column:nth-child(2) {
    border-left: none;
    border-right: none;
}
.home-instructions-content-column:last-child {
    border-right: none;
}
.home-instructions-content-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    gap: 50px;
    padding: 50px;
    height: 300px;
}
.home-instructions-content-column div span {
    color: #ffffff;
    background-color: #9e67ff;
    padding: 3px 10px;
    font-size: 1.8em;
    font-weight: 500;
}
.home-instructions-content-column div h2 {
    margin-bottom: 10px;
}
.home-instructions-content-column div p {
    font-size: 1.2em;
    font-weight: 400;
    color: #676767;
    margin-top: 0;
    margin-bottom: 0;
}
.home-instructions-content-column div i {
    font-size: 100px;
    color: #000000;
}
@media screen and (max-width: 780px)
{
    .home-instructions-content-column:first-child {
        border-left: none;
        border-right: none;
    }
    .home-instructions-content-column:nth-child(2) {
        border: none;
    }
    .home-instructions-content-column:last-child {
        border-right: none;
        border-left: none;
    }
    .home-instructions-title h1 {
        font-size: 2.5em;
    }
    .home-instructions-title p {
        font-size: 1.1em;
    }
    .home-instructions-content {
        flex-direction: column;
    }
    .home-instructions-content-column {
        width: 100%;
        min-height:400px;
        max-height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .home-instructions-content-item {
        gap: 40px;
        height: fit-content;
    }
    .home-instructions-content-column div span {
        font-size: 1.5em;
    }
    .home-instructions-content-column div h2 {
        font-size: 2em;
    }
    .home-instructions-content-column div p {
        font-size: 1.1em;
    }
    .home-instructions-content-column div i {
        font-size: 80px;
    }
}

/* Home Instructions (.dark) */

.dark .home-instructions-title h1 {
    color: #ffffff;
}
.dark .home-instructions-title p, .dark .home-instructions-content-column div p {
    color: #a7a7a7;
}
.dark .home-instructions-content-column div i {
    color: #ffffff;
}
.dark .home-instructions-content-column {
    border-color: #ffffff;
}

/* Home Banner 2 */

.home-banner-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
    height: 250px;
    background-color: #ceb2ff;
    padding: 0 10%;
}
.home-banner-2 img {
    height: auto;
    width: 500px;
    text-align: left;
}
.home-banner-2 h2 {
    font-size: 2.5em;
    font-weight: 600;
    color: #000000;
    margin: 0;
    text-align: right;
}
@media screen and (max-width: 1181px)
{
    .home-banner-2 {
        flex-direction: column-reverse;
        gap: 20px;
        height: fit-content;
        text-align: center;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .home-banner-2 img {
        text-align: center;
    }
    .home-banner-2 h2 {
        text-align: center;
    }
}
@media screen and (max-width: 768px)
{
    .home-banner-2 {
        padding: 30px 10px;
    }
    .home-banner-2 img {
        width: 300px;
    }
    .home-banner-2 h2 {
        font-size: 2em;
    }
}

/* Home Banner 2 (.dark) */

.dark .home-banner-2 {
    background-color: #9b7fd1;
}
.dark .home-banner-2 img {
    filter: grayscale(100%) brightness(0%);
}

/* Home Benefits (home-benefits) */

.home-benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
    padding: 0;
}
.home-benefits .btn-2 {
    background: transparent;
}
.home-benefits .btn-3 {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    width: fit-content;
}
.home-benefits-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 500px;
    width: 100%;
}
.home-benefits-item h1 {
    font-size: 3em;
    font-weight: 400;
    color: #000000;
    margin: 0;
    padding: 50px 0;
}
.home-benefits-item p {
    font-size: 1.2em;
    font-weight: 400;
    color: #000000;
    padding: 0;

}
.home-benefits-item:first-child .home-benefits-column:first-child {
    background-color: #ffceb2;
}
.home-benefits-item:first-child .home-benefits-column:last-child {
    background-color: rgba(255, 158, 103);
}
.home-benefits-item:nth-child(2) .home-benefits-column:first-child {
    background-color: rgba(103, 255, 158);
}
.home-benefits-item:nth-child(2) .home-benefits-column:last-child {
    background-color: #b2ffce;
}
.home-benefits-item:last-child .home-benefits-column:first-child {
    background-color: #ceb2ff;
}
.home-benefits-item:last-child .home-benefits-column:last-child {
    background-color: rgba(158, 103, 255);
}
.home-benefits-column {
    flex-basis: 50%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}
.home-benefits-column div {
    padding: 20px 50px;
}
.home-benefits-column div:last-child {
    padding-top: 0;
    padding-bottom: 70px;
}
.home-benefits-column img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: right;
    opacity: 50%;
}
.home-benefits-item:nth-child(2) img {
    opacity: 70%;
}
@media screen and (max-width: 768px)
{
    .home-benefits-column {
        flex-basis: 100%;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
    }
    .home-benefits-item:first-child .home-benefits-column:last-child, .home-benefits-item:last-child .home-benefits-column:last-child {
        display: none;
    }
    .home-benefits-item:nth-child(2) .home-benefits-column:first-child {
        display: none;
    }
}

/* Home Benefits (.dark) */

.dark .home-benefits-item:first-child .home-benefits-column:first-child {
    background-color: #d1895b;
}
.dark .home-benefits-item:first-child .home-benefits-column:last-child {
    background-color: #b7662c;
}
.darker .home-benefits-item h1 {
    font-size: 3em;
    font-weight: 400;
    color: #000000;
    margin: 0;
    padding: 50px 0;
}
.dark .home-benefits-item h1, .dark .home-benefits-item p {
    color: #ffffff;
}
.dark .home-benefits-item:nth-child(2) .home-benefits-column:first-child {
    background-color: #4dbf8a;
}
.dark .home-benefits-item:nth-child(2) .home-benefits-column:last-child {
    background-color: rgba(63, 204, 111, 0.9);
}
.dark .home-benefits-item:last-child .home-benefits-column:first-child {
    background-color: #b19be6;
}
.dark .home-benefits-item:last-child .home-benefits-column:last-child {
    background-color: #9b7fd1;
}

/* Home Testimonial (home-testimonial) */

.home-testimonial {
    background-size: 100px 100px;
    background-image:
            linear-gradient(to right, #000000 1px, transparent 1px),
            linear-gradient(to bottom, #000000 1px, transparent 1px);
    border-bottom: 1px solid #000000;
}
.home-testimonial p, .home-testimonial h1 {
    margin: 0;
    padding: 0;
}
.home-testimonial-card-container {
    padding: 10% 15%;
}
.home-testimonial-card {
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 75px;
    display: flex;
    flex-direction: column;
    gap: 100px;
    justify-content: center;
}
.home-testimonial-card h1 {
    font-weight: 400;
    font-size: 2em;
}
.home-testimonial-card p {
    background-color: #67FF9E;
    width: fit-content;
    font-family: "Sometype Mono", sans-serif;
    font-size: 1.1em;
}
.home-testimonial-card .bold {
    font-weight: 600;
}
@media screen and (max-width: 990px)
{
    .home-testimonial-card-container {
        padding: 10%;
    }
}
@media screen and (max-width: 868px)
{
    .home-testimonial-card-container {
        padding: 15% 5%;
    }
}
@media screen and (max-width: 770px)
{
    .home-testimonial-card  {
        gap: 50px;
        padding: 50px;
    }
}
@media screen and (max-width: 610px)
{
    .home-testimonial-card h1 {
        font-size: 1.5em;
    }
    .home-testimonial-card p {
        font-size: 0.8em;
    }
}
@media screen and (max-width: 486px)
{
    .home-testimonial-card  {
        padding: 30px 25px;
    }
}
@media screen and (max-width: 377px) {
    .home-testimonial-card p {
        font-size: 0.6em;
    }
}

/* Home Testimonial (.dark) */

.dark .home-testimonial-card {
    background-color: #000000;
    box-shadow: rgba(255, 255, 255, 0.35) 0px 5px 15px;
}
.dark .home-testimonial-card p {
    background-color: #3ad578;
    color: #000000;
}
.dark .home-testimonial {
    border-color: #ffffff;
    background-size: 100px 100px;
    background-image:
            linear-gradient(to right, #ffffff 1px, transparent 1px),
            linear-gradient(to bottom, #ffffff 1px, transparent 1px);
}

/* Home Discover (home-discover) */

.home-discover{
    background: radial-gradient(53.2% 178.72% at 27.1% 182.75%,#67FF9E 6.65%,#9b7fd1 100%);
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    padding-bottom: 100px;
    gap: 50px;
}
.home-discover h1 {
    font-size: 2.5em;
    color: #ffffff;
    font-weight: 600;
}
@media screen and (max-width: 868px)
{
    .home-discover h1 {
        margin: 0;
        padding: 0;
    }
}
.home-discover-more-content-wrapper {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    gap: 50px;
}
.home-discover-more-item {
    background-color: #303132;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    min-height: 300px;
    max-height: 300px;
    flex: 1;
}
@media screen and (max-width: 1072px)
{
    .home-discover-more-content-wrapper {
        flex-direction: column;
    }
    .home-discover-more-item {
        min-height: 250px;
        max-height: 250px;
    }
}
.home-discover-more-item h2, .home-discover-more-item p, .home-discover-more-item a {
    padding: 0;
    margin: 0;
}
.home-discover h2 {
    color: #ffffff;
}
.home-discover-more-item a:first-child {
    color: #c7c7c7;
    transition: color 0.3s linear;
}
.home-discover-more-item a:first-child:hover {
    color: #ffffff;
}
.home-discover-more-item-nav a:last-child {
    color: #ffffff;
    text-decoration: none;
    display: flex;
    gap: 15px;
    align-items: center;
    transition: transform 0.3s ease-out;
}
.home-discover-more-item-nav a:last-child:hover {
    transform: translateY(-5px);
}
.home-discover-more-item div:first-child i {
    color: #ffffff;
    font-size: 3em;
}
.home-discover p {
    color: #c7c7c7;
}
.home-discover-more-item div {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Home Discover (.dark) */

.dark .home-discover {
    background: radial-gradient(53.2% 178.72% at 27.1% 182.75%,#3ad578 6.65%,#9e67ff 100%);
}
.dark .home-discover-more-item {
    background-color: #000000;
}

/* Privacy Policy (privacy-policy.js) */

/* Privacy Policy (privacy-policy) */

.privacy-policy {
    padding: 50px 10%;
}
.privacy-policy h1 {
    font-size: 2.5em;
}
.privacy-policy h2 {
    font-size: 2em;
}
.privacy-policy p {
    font-size: 1.2em;
}
.privacy-policy a {
    color: #000000;
    transition: color 0.3s linear;
}
.privacy-policy a:hover {
    color: #303132;
}

/* Privacy Policy (.dark) */

.dark .privacy-policy a {
    color: #ffffff;
    transition: color 0.3s linear;
}
.dark .privacy-policy a:hover {
    color: #c7c7c7;
}

/* Terms of Use (terms-of-use.js) */

/* Terms of Use (terms-of-use) */

.terms-of-use {
    padding: 50px 10%;
}
.terms-of-use h1 {
    font-size: 2.5em;
}
.terms-of-use h2 {
    font-size: 2em;
}
.terms-of-use p {
    font-size: 1.2em;
}
.terms-of-use a {
    color: #000000;
    transition: color 0.3s linear;
}
.terms-of-use a:hover {
    color: #303132;
}

/* Terms of Use (.dark) */

.dark .terms-of-use a {
    color: #ffffff;
    transition: color 0.3s linear;
}
.dark .terms-of-use a:hover {
    color: #c7c7c7;
}

/* Legal (legal.js) */

/* Legal (legal) */

.legal {
    padding: 50px 10%;
}
.legal h1 {
    font-size: 2.5em;
}
.legal h2 {
    font-size: 2em;
}
.legal p {
    font-size: 1.2em;
}
.legal a {
    color: #000000;
    transition: color 0.3s linear;
}
.legal a:hover {
    color: #303132;
}

/* Terms of Use (.dark) */

.dark .legal a {
    color: #ffffff;
    transition: color 0.3s linear;
}
.dark .legal a:hover {
    color: #c7c7c7;
}

/* Error 404 (error.js) */

/* Error 404 (error) */

.error {
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}
@media screen and (max-width: 1100px) {
    .error {
        padding: 10% 5%;
    }
}
.error h1, .error p {
    text-align: center;
    margin: 0;
}
.error p {
    font-size: 1.2em;
}
.error h1:first-child {
    color: #9e67ff;
    font-size: 10em;
    font-weight: 900;
}
.error h1:last-child {
    font-size: 3em;
    font-weight: 900;
    text-transform: uppercase;;
}
@media screen and (max-width: 600px) {
    .error h1:first-child {
        font-size: 8em;
    }
    .error h1:last-child {
        font-size: 2.5em;
    }
}
.error div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 15px;
}
.error div:last-child a {
    transition: color 0.3s;
    color: #000000;
    text-decoration: none;
    font-size: 20px;
}
.error div:last-child a:hover {
    color: #303132;
}

/* Error 404 (.dark) */

.dark .error div:last-child a {
    color: #ffffff;
}
.dark .error div:last-child a:hover {
    color: #878787;
}

/* Careers (careers.js) */

/* Careers masthead (.careers-masthead) */

.careers-masthead {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 40px;
    padding: 90px 6%;
}
.careers-masthead-column {
    flex-basis: 50%;
}
.careers-masthead-column:first-child {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.careers-masthead-column:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
}
.careers-masthead-row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}
.careers-masthead-column:first-child .careers-masthead-row:last-child {
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
}
.careers-masthead h1 {
    font-weight: 500;
    font-size: 4em;
    margin: 0;
}
.careers-masthead p {
    font-size: 1.4em;
    font-weight: 400;
    color: #303132;
}
.careers-masthead img {
    width: 500px;
    height: auto;
}
.internships-anchor {
    font-size: 1.2em;
    font-weight: 400;
    color: #9e67ff;
    transition: color 0.3s;
}
.internships-anchor:hover {
    color: #7a4dbf;
}
@media screen and (max-width: 1119px) {
    .careers-masthead {
        flex-direction: column;
        gap: 0;
        padding: 70px 3%;
        align-items: flex-start;
    }
    .careers-masthead h1 {
        font-size: clamp(48px,7vw,84px);
    }
    .careers-masthead p {
        font-size: clamp(20px,2vw,40px);
    }
    .careers-masthead-column {
        flex-basis: unset !important;
    }
    .careers-masthead-column:first-child .careers-masthead-row:last-child {
        flex-direction: column;
        gap: 20px;
    }
    .careers-masthead-column:first-child .careers-masthead-row:last-child div {
        width: 100%;
    }
    .careers-masthead-column:last-child .internships-anchor {
        text-align: left
    }
    .careers-masthead .btn-1 {
        width: 100%;
    }
    .careers-masthead img {
        display: none;
    }
}

/* Careers call-to-action (.careers-cta) */

.careers-cta {
    display: flex;
    align-items: center;
    justify-content: initial;
    flex-direction: column;
    background-color: #ceb2ff;
    padding-top: 90px;
    height: 800px;
}
@media screen and (max-width: 1300px) {
    .careers-cta {
        height: 700px;
    }
    .careers-video {
        margin-top: -250px !important;
    }
}
@media screen and (max-width: 700px) {
    .careers-cta {
        height: 600px;
    }
    .careers-video {
        margin-top: -180px !important;
    }
}
@media screen and (max-width: 530px) {
    .careers-video {
        margin-top: -140px !important;
    }
}
@media screen and (max-width: 411px) {
    .careers-video {
        margin-top: -100px !important;
    }
}
@media screen and (max-width: 382px) {
    .careers-cta {
        height: 650px;
    }
    .careers-video {
        margin-top: -80px !important;
    }
}
.careers-cta p, .careers-cta h1 {
    text-align: center;
}
.careers-cta h1 {
    font-size: 3em;
    font-weight: 500;
    margin: 0;
}
.careers-cta div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.careers-cta p {
    font-size: 1.4em;
    font-weight: 400;
    max-width: 70vw;
    padding: 20px 0;
}
@media screen and (max-width: 515px) {
    .careers-cta p {
        max-width: 98%;
    }
}

/* Careers video (.careers-video) */

.careers-video {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -360px;
}
.careers-video div {
    padding: 30px;
    background-color: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
@media screen and (max-width: 645px) {
    .careers-video div {
        padding: 20px;
    }
}
.careers-video iframe {
    height: 40vw;
    width: 80vw;
}

/* Careers roles (.careers-roles) */

.careers-roles {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}
.careers-roles-title {
    background-color: #ceb2ff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 90px 0;
}
.careers-roles-title h1 {
    font-size: 3em;
    font-weight: 500;
    margin: 0;
    text-align: center;
}
.careers-roles-title p {
    font-size: 1.4em;
    font-weight: 400;
    margin: 0;
    text-align: center;
    max-width: 70vw;
}
@media screen and (max-width: 515px) {
    .careers-roles-title p {
        max-width: 94%;
    }
}
.careers-roles-listings {
    padding: 90px 0;
    margin: 0 3%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 90%;
}
.careers-role {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}
@media screen and (max-width: 550px) {
    .careers-role {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }
}
.careers-role h1 {
    font-size: 1.4em;
    font-weight: 500;
    margin: 0;
}
.careers-role p {
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
    color: #303132;
}
.careers-role a {
    padding: 8px 10px;
    font-size: 1em;
}
.careers-roles-listings h2 {
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
    text-align: center;
}

/* Careers (.dark) */

.dark .careers-masthead p, .careers-role p {
    color: #c7c7c7;
}
.dark .careers-cta, .dark .careers-roles-title {
    background-color: #9b7fd1;
}
.dark .careers-video div {
    background-color: #0e0e0e;
}
.dark .internships-anchor {
    color: #9b7fd1;
}

/* Careers values (.careers-values) */

.careers-values {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 90px 6%;
    gap: 50px;
}
.careers-value-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    gap: 40px;
    text-align: left;
}
@media screen and (max-width: 939px) {
    .careers-value-item {
        flex-direction: column;
        gap: 20px;
    }
}
.careers-value-item h1 {
    font-size: 2em;
}
.careers-value-item p {
    font-size: 1.2em;
}
.careers-value-item h1, .careers-value-item p {
    margin: 0;
    padding: 0;
}
.careers-value-item div {
    flex-basis: 50%;
}
.careers-values hr {
    border: none;
    border-top: 1px solid #000000;
    margin-top: 6%;
    margin-bottom: 6%;
    width: 100%;
}

/* Careers values (.dark) */

.dark .careers-values hr {
    border-color: #ffffff;
}

/* Login (.login) */

.login {
    background: linear-gradient(to top, #ffffff, #d8c3fb);
    height: 100vh;
}
.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    height: 100%;
}
.login-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
}
.login-title img {
    height: 100px;
    width: auto;
    margin: -50px -30px;
}
.login-title h1 {
    font-size: 1.6em;
    font-weight: 500;
    margin: 0;
    font-weight: 600;
    text-align: center;
}
.login-form {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 40px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.login-form form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    width: 450px;
}
@media screen and (max-width: 700px) {
    .login-form form {
        width: 350px;
    }
    .login-form-nav a {
        font-size: 0.8em;
    }
}
@media screen and (max-width: 500px) {
    .login-form form {
        width: 280px;
    }
    .login-form {
        padding: 30px;
    }
    .login-form-nav {
        flex-direction: column !important;
        gap: 10px;
    }
}
@media screen and (max-width: 400px) {
    .login-form form {
        width: 220px;
    }
}
.login-form form fieldset {
    width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0;
}
.login-form form fieldset label {
    font-weight: 600;
}
.login-form form fieldset input {
    width: 100%;
    padding: 12px;
    border-right: none;
    border-top: none;
    border-bottom: none;
    font-size: 0.9em;
    border-left: 1px solid #000000;
}
.login-form form fieldset input:focus {
    outline: none;
}
.login-input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: 1px solid #000000;
}
.login-input i {
    font-size: 1em;
    color: #000000;
    padding: 12px;
}
.login-form-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.login-form-nav a {
    color: rgb(0,0,255);
}
.login-form-nav a:hover {
    text-decoration: none;
}
.login-main {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.login-products {
    display: grid;
    /* Replace and change for number of product-items */
    grid-template-columns: repeat(1, 40px);
    justify-content: center;
    align-items: center;
    gap: 15px;
}
.login-product {
    background-color: #ffffff;
    padding: 4px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.login-product img {
    width: 30px;
    height: 30px;
}
.login-footer p {
    text-align: center;
    margin: 0;
    padding: 0;
}

/* Register (.register) */

.register {
    background: linear-gradient(to top, #ffffff, #d8c3fb);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
}
.register-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}
.register-container div {
    width: 100%;
}
.register-title img {
    height: 160px;
    width: auto;
    margin: -50px -30px;
}
.register-title-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
}
.register-title-item div:last-child {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.register-title h1 {
    text-align: center;
    margin-bottom: 0;
    font-size: 1.6em;
}
.register-title-item a {
    text-align: right;
    width: 100%;
    color: rgb(0,0,255);
}
.register-title-item a:hover {
    text-decoration: none;
}
.register-form {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}
.register-form form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    width: 100%;
}
@media screen and (max-width: 700px) {
    .register-form {
        padding: 0;
    }
    .register-form form {
        width: 400px;
    }
    .register-form-nav a, .register-form-nav p, .register-footer p {
        font-size: 0.8em;
    }
    .register-form-nav p {
        padding: 0 30px;
    }
    .register-title-item a {
        text-align: center;
    }
    .register-title img {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    .register-title-item {
        flex-direction: column;
    }
}
@media screen and (max-width: 500px) {
    .register-form form {
        width: 300px;
    }
    .register-form-nav {
        flex-direction: column !important;
        gap: 10px;
    }
}
@media screen and (max-width: 400px) {
    .register-form form {
        width: 250px;
    }
}
.register-form form fieldset {
    width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 0;
}
.register-form form fieldset label {
    font-weight: 600;
}
.register-form form fieldset input {
    width: 100%;
    padding: 12px;
    border-right: none;
    border-top: none;
    border-bottom: none;
    font-size: 0.9em;
    border-left: 1px solid #000000;
}
.register-form form fieldset input:focus {
    outline: none;
}
.register-input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border: 1px solid #000000;
    background-color: #ffffff;
}
.register-input i {
    font-size: 1em;
    color: #000000;
    padding: 12px;
}
.register-footer p {
    text-align: center;
    margin: 0;
    padding: 0;
}
.register-form-nav {
    text-align: center;
}
.register-form-nav a {
    color: rgb(0,0,255);
}
.register-form-nav a:hover {
    text-decoration: none;
}
.register-input checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
}
.register-input checkbox input {
    width: auto !important;
}
.register-checkbox {
    border: none;
    background-color: transparent;
}

/* Error message */

.error-message {
    display: none;
    position: fixed;
    bottom: 15px;
    right: 15px;
    background-color: red;
    color: white;
    font-size: 1em;
    padding: 8px 12px;
    font-weight: 600;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.error-message.visible {
    display: flex;
}

/* Pricing (.pricing) */

/* Pricing masthead (.pricing-masthead) */

.pricing-masthead {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 90px 0;
    background-color: #ceb2ff;
}
.pricing-masthead h1, .pricing-masthead p, .pricing-masthead a {
    margin: 0;
    text-align: center;
}
.pricing-masthead h1 {
    font-size: 4em;
    font-weight: 500;
}
.pricing-masthead p {
    font-size: 1.4em;
    font-weight: 400;
}
@media screen and (max-width: 1119px) {
    .pricing-masthead {
        flex-direction: column;
        padding: 70px 3%;
    }
    .pricing-masthead h1 {
        font-size: clamp(48px,7vw,84px);
    }
    .pricing-masthead p {
        font-size: clamp(20px,2vw,40px);
    }
}
.scroll-down-btn {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #000000;
    gap: 15px;
    padding-top: 40px;
    transition: transform 0.4s ease-out;
}
.scroll-down-btn:hover {
    transform: translateY(10px);
}
/* Pricing masthead (.dark) */

.dark .scroll-down-btn {
    color: #ffffff;
}
.dark .pricing-masthead {
    background-color: #9b7fd1;
}

/* Featured prices (.featured-prices) */

.featured-prices {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 90px 6%;

}
.featured-prices-row:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
}
.featured-prices-row:first-child img {
    width: auto;
    height: 200px;
    margin-top: -100px;
    margin-bottom: -100px;
}
.featured-prices-row:first-child p {
    font-size: 1.4em;
    font-weight: 400;
    text-align: center;
}
.featured-prices-row:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 50px;
}
.featured-price-1 {
    border: 2px solid #000000;
}
.featured-price-1-container, .featured-price-2-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 35px;
    align-items: center;
    justify-content: center;
}
.featured-price-2 {
    border: 2px solid #9e67ff;
}
.featured-prices-column-container {
    border-radius: 10px;
    max-width: 425px; /* Setting a max width to keep the pricing boxes smaller */
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.popular-banner {
    visibility: hidden;
}
.recommended-banner, .popular-banner {
    background-color: #9e67ff;
    color: #ffffff;
    padding: 10px 0;
    font-size: 1em;
    text-align: center;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
}
.recommended-banner h3, .popular-banner h3 {
    margin: 0;
    padding: 0;
    font-weight: 600;
}
.featured-prices-column-container h2, .featured-prices-column-container p {
    margin: 0;
    padding: 0;
}
.featured-prices-column-container h2 {
    font-size: 1.6em;
}
.featured-prices-column-container p {
    font-size: 1em;
    font-weight: 400;
    text-align: center;
}
.featured-prices-column-container i {
    font-size: 3em;
}
.featured-prices-column-container h4 {
    font-size: 3em;
    font-weight: 600;
    margin: 0;
}
.price {
    padding: 15px 0;
}
/* Override default btn styles */
.pricing-cta-btn {
    font-size: 1.6em;
    padding: 30px 60px;
}
.featured-prices-column-container .divider {
    width: 100%;
}
.featured-prices-column-container hr {
    width: 100%;
    border: none;
    border-top: 1px solid #000000;
}
.featured-prices-column-container ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.featured-prices-column-container ul li {
    display: flex;
    align-items: center;
    gap: 10px;
}
.featured-prices-column-container ul li i {
    font-size: 1.6em;
}
.featured-price-2-container ul li i {
    color: #9e67ff;
}
.compare-link {
    padding: 15px 0;
}
.compare-link a {
    color: #2962ff;
}
.legal-price {
    font-size: 0.8em;
    color: #303132;
}
@media screen and (max-width: 830px) {
    .featured-prices-row:last-child {
        flex-direction: column-reverse;
        gap: 30px;
    }
    .featured-prices-column-container {
        width: 100%;
    }
    .featured-price-1-container, .featured-price-2-container {
        padding: 20px;
    }
    .featured-prices-column-container h2 {
        font-size: 1.4em;
    }
    .featured-prices-column-container p {
        font-size: 0.9em;
    }
    .featured-prices-column-container i {
        font-size: 2.5em;
    }
    .featured-prices-column-container h4 {
        font-size: 2.5em;
    }
    .price {
        padding: 10px 0;
    }
    .pricing-cta-btn {
        font-size: 1.4em;
        padding: 20px 40px;
    }
    .featured-prices-column-container ul li i {
        font-size: 1.4em;
    }
    .compare-link {
        padding: 10px 0;
    }
    .legal-price {
        font-size: 0.7em;
    }

}

/* Featured prices (.dark) */

.light-frim-cloud-logo {
    display: none;
}
.dark .dark-frim-cloud-logo {
    display: none;
}
.dark .light-frim-cloud-logo {
    display: block;
    padding-top: 18px; /* Evens out logo alignment due to unknown mismatch cause */
}
.dark .featured-price-1 {
    border-color: #ffffff;
}
.dark .legal-price {
    color: #c7c7c7;
}

/* Corporation pricing (.corporation-pricing) */

.corporation-pricing {
    display: flex;
    align-items: center;
    justify-content: center;
}
.corporation-pricing-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    padding: 60px;
    background-color: #303132;
    border-radius: 10px;
    width: 80%;
}
@media screen and (max-width: 1149px) {
    .corporation-pricing-container {
        flex-direction: column;
        gap: 30px;
        align-items: flex-start;
        padding: 45px;
        width: 100%;
        margin: 0 20px;
    }
}
@media screen and (max-width: 500px) {
    .corporation-pricing {
        background-color: #303132;
    }
    .dark .corporation-pricing {
        background-color: #000000;
    }
    .corporation-pricing-container h1 {
        font-size: 1.8em;
    }
    .corporation-pricing-container p {
        font-size: 1em;
    }
}
.corporation-pricing-container div:first-child {
    flex-basis: 70%;
}
.corporation-pricing-container h1 {
    font-size: 2.5em;
    color: #ffffff;
    margin: 0;
    font-weight: 600;
    padding-bottom: 20px;
}
.corporation-pricing-container p {
    font-size: 1.4em;
    color: #ffffff;
    margin: 0;
    font-weight: 300;
}

/* Corporation pricing (.dark) */

.dark .corporation-pricing-container {
    background-color: #000000;
    border: 2px solid #ffffff;
}

/* Pricing plans (.pricing-plans) */

.table-wrapper {
    overflow-x: auto;
    margin: 0 auto;
    width: 100%;
}
.pricing-plans {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 90px 6%;
}
.pricing-plans table {
    border-spacing: 0;
    width: 100%;
}
.pricing-plans table tr {
    border: none;
}
.pricing-plans table th {
    min-width: 200px;
    padding: 20px 0;
}
.pricing-plans td, .pricing-plans .th-content {
    padding: 10px 30px;
    border: 1px solid #eeeeee;
}
.pricing-plans .th-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
}
.pricing-plans .th-content p {
    margin: 0;
    padding: 0;
}
.pricing-plans .th-content .table-price {
    font-weight: 400;
}
.pricing-plans table .sub-header {
    background-color: #eeeeee;
    font-weight: 500;
}
.pricing-plans table .no-border {
    border: none;
}
.small-pricing-cta-btn {
    font-size: 1em;
    padding: 5px 12px;
}
.center-cell-text {
    text-align: center;
}
.center-cell {
    text-align: center;
    width: 100%;
}
.top-left-corner-radius {
    border-top-left-radius: 10px;
}
.top-right-corner-radius {
    border-top-right-radius: 10px;
}
/* Styling for the recommended pricing model */
.recommended-border-top {
    border: 2px solid #9e67ff !important;
    border-bottom: 0 !important;
    padding: 0;
}
.recommended-border-bottom {
    border: 2px solid #9e67ff !important;
    border-top: 0 !important;
}
.recommended-cell-border {
    border: 2px solid #9e67ff !important;
    border-top: 1px solid #eeeeee !important;
    border-bottom: 1px solid #eeeeee !important;
}
.pricing-plans .hidden {
    visibility: hidden;
}
.recommended-thead td {
    border: none !important;
}
.dark .pricing-plans table .no-border {
    border: none;
}

/* Pricing plans (.dark) */

.dark .pricing-plans td, .dark .pricing-plans .th-content {
    border: 1px solid #303132;
}
.dark .pricing-plans table .sub-header {
    background-color: #303132;
}
.dark .recommended-cell-border {
    border: 2px solid #9e67ff !important;
    border-top: 1px solid #303132 !important;
    border-bottom: 1px solid #303132 !important;
}

/* Pricing CTA (.pricing-cta) */

.pricing-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 90px 0;
    background-color: #ceb2ff;
}
.pricing-cta h1, .pricing-cta p {
    margin: 0;
    text-align: center;
}
.pricing-cta h1 {
    font-size: 3em;
    font-weight: 500;
}
.pricing-cta p {
    font-size: 1.2em;
    font-weight: 400;
}

/* Pricing CTA (.dark) */

.dark .pricing-cta {
    background-color: #9b7fd1;
}

/* Maintenance (maintenance.js) */

.maintenance {
    padding: 50px 10%;
}
.maintenance h1 {
    font-size: 2.5em;
}
.maintenance h2 {
    font-size: 2em;
}
.maintenance p {
    font-size: 1.2em;
}
.maintenance a {
    color: #000000;
    transition: color 0.3s linear;
}
.maintenance a:hover {
    color: #303132;
}
.maintenance-status {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 10px;
    font-weight: 600;
}
.maintenance-status-dot {
    width: 12px;
    height: 12px;
    background-color: red; /* Change to green for online */
    border-radius: 50%;
    margin-right: -3px;
}
.maintenance hr {
    border: none;
    border-top: 1px solid #000000;
    margin-top: 6%;
    margin-bottom: 6%;
    width: 100%;
}

/* Maintenance (.dark) */

.dark .maintenance a {
    color: #ffffff;
    transition: color 0.3s linear;
}
.dark .maintenance a:hover {
    color: #c7c7c7;
}
.dark .maintenance hr {
    border-color: #ffffff;
}

/* About (about.js) */

.about {
    padding: 0;
}

/* About masthead (.about-masthead) */

.about-masthead {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    padding: 90px 6%;
    background-color: #303132;
}
.about-masthead h1 {
    font-size: 4em;
    font-weight: 500;
    margin: 0;
    color: #ffffff;
}
.about-masthead span {
    color: #9e67ff;
    font-weight: 600;
}
.about-masthead p {
    font-size: 1.4em;
    font-weight: 400;
    color: #ffffff;
}
@media screen and (max-width: 1119px) {
    .about-masthead {
        flex-direction: column;
        gap: 20px;
        padding: 70px 3%;
        align-items: flex-start;
    }
    .about-masthead h1 {
        font-size: clamp(48px,7vw,84px);
    }
    .about-masthead p {
        font-size: clamp(20px,2vw,40px);
    }
}

/* About masthead (.dark) */

.dark .about-masthead {
    background-color: #000000;
}
.dark .about-masthead h1, .dark .about-masthead p {
    color: #ffffff;
}

/* About highlights (.about-highlights) */

.about-highlights {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
    padding: 90px 6%;
    background-color: #ceb2ff;
}
.about-highlight-row h1 {
    font-size: 2.5em;
    font-weight: 500;
    margin: 0;
}
.about-highlight-row:last-child {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 50px;
    width: 100%;
}
.about-highlight-row h2 {
    font-size: 4em;
    font-weight: 600;
    margin: 0;
}
.about-highlight-row p {
    font-size: 1.2em;
    font-weight: 400;
    margin: 0;
}

/* About highlights (.dark) */

.dark .about-highlights {
    background-color: #9b7fd1;
}

/* About story (.about-story) */

.about-story {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 50px;
    padding: 90px 6%;
}
.about-story-column {
    flex-basis: 50%;
}
.about-story h1 {
    font-size: 2.5em;
    font-weight: 500;
}
.about-story p {
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.6;
}
.about-story-column:first-child {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.about-story-column:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
}
.about-story img {
    width: 350px;
    height: 250px;
    position: absolute;
    object-fit: cover;
    border-radius: 15px;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}
.about-story-img-1 {
    transform: translate(80px, 100px);
    z-index: 1;
}
@media screen and (max-width: 1191px) {
    .about-story img {
        display: none;
    }
    .about-story-column {
        flex-basis: unset;
    }
    .about-story {
        gap: 0;
    }
}

/* About Founder (.about-founder) */

.about-founder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 50px;
    padding: 20px 90px 6% 6%;
}
.about-founder-column img {
    width: 500px;
    height: auto;
    border-radius: 15px;
}
.about-founder-column h1 {
    font-size: 2.5em;
    font-weight: 500;
}
.about-founder p {
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.6;
}
.founder-socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    margin-top: 30px;
}
.founder-socials i {
    color: #000000;
    font-size: 2.5em;
    transition: color 0.3s ease-in-out;
}
.founder-socials i:hover {
    color: #303132;
}
@media screen and (max-width: 1171px) {
    .about-founder {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }
    .about-founder-column img {
        width: 250px;
        height: 300px;
        object-fit: cover;
        object-position: top;
    }
    .founder-socials i {
        font-size: 2em;
    }
}

/* About Founder (.dark) */

.dark .founder-socials i {
    color: #ffffff;
}
.dark .founder-socials i:hover {
    color: #c7c7c7;
}

/* About CTA (.about-cta) */

.about-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    padding: 90px 0;
    background-color: #ceb2ff;
}
.about-cta h1, .about-cta p {
    margin: 0;
    text-align: center;
}
.about-cta h1 {
    font-size: 3em;
    font-weight: 500;
}
.about-cta p {
    font-size: 1.2em;
    font-weight: 400;
}

/* About CTA (.dark) */

.dark .about-cta {
    background-color: #9b7fd1;
}

/* Ceasar Mobile Landing (.ceasar-mobile-landing.js) */

.ceasar-mobile-landing {
    min-height: 100vh;
    margin: 0;
    background: linear-gradient(to top, #FF9e67, #FFDFA5);
}
.ceasar-mobile-landing-container {
    height: 100%;
}

/* Ceasar Mobile Landing Header */

.ceasar-mobile-landing-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
    padding: 30px 6% 0 6%;
}
.ceasar-mobile-landing-header img {
    width: 180px;
    height: auto;
    margin: -10px 0;
}
.ceasar-mobile-landing-header h1 {
    font-size: 1.4em;
    font-weight: 700;
    margin: 0;
}
.ceasar-mobile-landing-header div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.ceasar-mobile-landing-header div:first-child {
    gap: 0;
}
.ceasar-mobile-landing-header div:last-child {
    gap: 20px;
}
.ceasar-mobile-landing-socials i {
    font-size: 1.5em;
    color: #000000;
    transition: color 0.3s ease-in-out;
}
.ceasar-mobile-landing-socials i:hover {
    color: #303132;
}
@media screen and (max-width: 500px) {
    .ceasar-mobile-landing-socials {
        display: none !important;
    }
    /* Center everything */
    .ceasar-mobile-landing-header {
        justify-content: center;
    }
    .ceasar-mobile-landing-content h1, .ceasar-mobile-landing-content p, .ceasar-mobile-landing-content span {
        text-align: center;
    }
    .ceasar-mobile-landing-download-btns {
        justify-content: center;
    }
    .ceasar-mobile-landing-content-column:last-child {
        justify-content: center;
    }
    .ceasar-mobile-landing-content-column {
        align-items: center !important;
    }
}

/* Ceasar Mobile Landing Content */

.ceasar-mobile-landing-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    padding: 0 6%;
    /* height: 90%; */
}
.ceasar-mobile-landing-content-column {
    flex-basis: 50%;
    min-height: 80vh;
}
.ceasar-mobile-landing-content-column:first-child {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    padding: 50px 0;
}
.ceasar-mobile-landing-content-column:last-child {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.ceasar-mobile-landing-content-column h1 {
    font-size: 3.5em;
    font-weight: 600;
    margin: 0;
    padding-bottom: 20px;
}
.ceasar-mobile-landing-content-column p {
    font-size: 1.4em;
    font-weight: 400;
    margin: 0;
}
.ceasar-mobile-landing-content-column a:first-child img {
    height: 80px;
}
.ceasar-mobile-landing-content-column a:last-child img {
    height: 118px;
}
.ceasar-mobile-landing-download-btns {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.ceasar-crm-mobile-app-img {
    width: 500px;
    height: auto;
}
@media screen and (max-width: 1400px) {
    .ceasar-crm-mobile-app-img {
        width: 400px;
    }
}
@media screen and (max-width: 1147px) {
    .ceasar-mobile-landing-content-column h1 {
        font-size: 3em;
    }
    .ceasar-mobile-landing-content-column p {
        font-size: 1.2em;
    }
    .ceasar-crm-mobile-app-img {
        height: 200px;
        width: 300px;
        object-fit: cover;
        object-position: top;
        border-bottom: 5px solid #000000;
        margin-top: 30px;
    }
    .ceasar-mobile-landing-content {
        flex-direction: column-reverse;
        min-height: 90vh;
    }
    .ceasar-mobile-landing-content-column {
        flex-basis: unset;
        min-height: auto;
    }
    .ceasar-mobile-landing-content-column:last-child {
       justify-content: flex-start;
       align-items: unset;
    }
    .ceasar-mobile-landing-content {
        align-items: unset;
    }
    .ceasar-mobile-landing-content-column:first-child {
        padding: 20px 0 50px 0;
    }
}
@media screen and (max-width: 660px) {
    .ceasar-mobile-landing-content-column h1 {
        font-size: 2.5em;
    }
    .ceasar-mobile-landing-content-column p {
        font-size: 1em;
    }
    .ceasar-crm-mobile-app-img {
        height: 150px;
        width: 250px;
    }
    .ceasar-mobile-landing-content-column a:first-child img {
        height: 50px;
    }
    .ceasar-mobile-landing-content-column a:last-child img {
        height: 72px;
    }
}
@media screen and (max-width: 425px) {
    .ceasar-mobile-landing-content-column h1 {
        font-size: 2em;
    }
}
@media screen and (max-width: 375px) {
    .ceasar-mobile-landing-content-column a:first-child img {
        height: 36px;
    }
    .ceasar-mobile-landing-content-column a:last-child img {
        height: 52px;
    }
}